import Alert from '@material-ui/lab/Alert';
import Api from '../../utils/api';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import InputLabel from '@material-ui/core/InputLabel';
import LinearProgress from '@material-ui/core/LinearProgress';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import React, {useContext, useEffect, useState} from 'react';
import Select from '@material-ui/core/Select';
import Switch from '@material-ui/core/Switch';
import TextField from '@material-ui/core/TextField';
import {ColorPicker} from 'material-ui-color';
import {Grid, withStyles} from '@material-ui/core';
import {Link as RouterLink, Redirect} from 'react-router-dom';
import {UserContext} from '../../providers/UserProvider';
import {fonts} from '../../utils/fonts';

export default function Form(props) {
    const user = useContext(UserContext);

    const [theme, setTheme] = useState({
        id: '',
        visible: false,
        premium: false,
        paint_icon: false,
        is_new: false,
        image: '',
        name: '',
        category: '',
        font_color: '',
        font_name: '',
    });
    const [alert, setAlert] = useState({
        open: false,
        success: false,
        text: '',
    });
    const [preview, setPreview] = useState({
        text: 'Manifest',
        styles: {
            key: '',
            title: '',
            font: {
                family: '',
                weight: 0,
                italic: false,
            },
        },
    });
    const [loading, setLoading] = useState(false);

    const [redirect, setRedirect] = useState(false);

    const [deleteBox, setDeleteBox] = useState({
        open: false,
        id: '',
        message: '',
    });

    function errorAlert(err) {
        setAlert({open: true, success: false, text: err[0] === undefined ? err : err[0].toUpperCase() + err.slice(1)})
    }

    useEffect(() => {
        if (!props.id || !user.accessToken) return

        setLoading(true);

        Api().themes.get(user.accessToken, props.id, user.platform)
            .then(theme => {
                setTheme(theme)

                const filtered = fonts.filter(font => font.key === theme.font_name)
                if (filtered.length > 0) {
                    setPreview({...preview, styles: filtered[0]})
                } else {
                    alert('Font not found: ' + theme.font_name)
                }
            })
            .catch(err => errorAlert(err))
            .then(() => setLoading(false))
    }, [props.id, user.accessToken, user.platform]);

    const handleChanges = event => {
        let {name, value} = event.target

        if (name === 'visible' || name === 'paint_icon' || name === 'premium' || name === 'is_new') {
            value = event.target.checked
        }

        if (name === 'font_name') {
            const filtered = fonts.filter(font => font.key === value);
            if (filtered.length > 0) {
                setPreview({...preview, styles: filtered[0]})
            } else {
                alert('Font not found: ' + value)
            }
        }

        setTheme(prevState => ({...prevState, [name]: value}))
    }

    const handlePreviewChanges = event => {
        const {value} = event.target
        setPreview({...preview, text: value})
    }

    const setColor = colorInfo => {
        if (colorInfo === undefined) {
            return
        }

        setTheme({...theme, font_color: '#' + colorInfo.hex})
    }

    const createTheme = () => {
        setLoading(true);

        Api().themes.create(user.accessToken, theme, user.platform)
          .then((data) => {
              if (data.id && data.position) {
                      return Api().themes.reorder(user.accessToken, data.id, data.position - 1 , 0, user.platform).then(() => {
                          user.setAlert('Theme successfully saved theme position changed to 1')
                          setRedirect(true)
                      })
              }
          })
            .catch(err => errorAlert(err))
            .then(() => setLoading(false))
    }

    const updateTheme = () => {
        setLoading(true)

        Api().themes.update(user.accessToken, props.id, theme, user.platform)
            .then(() => {
                user.setAlert('Theme successfully updated!')
                setRedirect(true)
            })
            .catch(err => errorAlert(err))
            .then(() => setLoading(false))
    }

    const handleImageUpload = ({target}) => {
        setLoading(true);

        const formData = new FormData();
        formData.append('file', target.files[0]);

        Api().uploadImage(user.accessToken, formData, user.platform)
            .then(response => setTheme({...theme, image: response.href}))
            .catch(err => errorAlert(err))
            .then(() => setLoading(false))
    }

    const BlackAndWhiteSwitch = withStyles({
        switchBase: {
            color: 'white',
            '&$checked': {
                color: 'black',
            },
            '&$checked + $track': {
                backgroundColor: 'black',
            },
        },
        checked: {},
        track: {},
    })(Switch);

    if (redirect) {
        return <Redirect to="/themes"/>
    }

    const handleOpenDeleteDialog = (id) => {
        setDeleteBox({id: id, open: true});
    }

    const handleCloseDeleteDialog = () => {
        setDeleteBox({...deleteBox, open: false});
    }

    const handleDelete = () => {
        setLoading(true);

        Api().themes.delete(user.accessToken, deleteBox.id, user.platform)
            .catch(err => errorAlert(err))
            .then(() => {
                handleCloseDeleteDialog()
                user.setAlert('Theme successfully deleted')
                setRedirect(true)
            })
    }

    return <Paper>
        {loading && <LinearProgress/>}
        <Box p={3}>
            {alert.open && <Alert style={{marginBottom: 10}} severity={alert.success ? 'success' : 'error'} onClose={
                () => setAlert({...alert, open: false})
            }>{alert.text}</Alert>}

            <Grid container spacing={4}>
                <Grid item xs={6}>
                    <FormControl fullWidth margin="normal">
                        <TextField
                            disabled={loading}
                            label="Name"
                            name="name"
                            onChange={handleChanges}
                            value={theme.name}
                        />
                    </FormControl>
                    <FormControl fullWidth margin="normal">
                        <TextField
                          disabled={loading}
                          label="Category"
                          name="category"
                          onChange={handleChanges}
                          value={theme.category}
                        />
                    </FormControl>
                    <FormControl fullWidth margin="normal">
                        <ColorPicker
                            disabled={loading}
                            label="Font color"
                            placeholder="Hex code format: `#cdcdcd`"
                            name="font_color"
                            defaultValue={theme.font_color}
                            onChange={setColor}
                            value={theme.font_color}
                        />
                    </FormControl>
                    <FormControl fullWidth margin="normal">
                        <InputLabel id="font-name-label">Font name</InputLabel>
                        <Select
                            disabled={loading}
                            labelId="font-name-label"
                            name="font_name"
                            value={theme.font_name}
                            onChange={handleChanges}
                        >
                            {fonts.map(font => <MenuItem key={font.key} value={font.key}>{font.title}</MenuItem>)}
                        </Select>
                    </FormControl>

                    <FormControl fullWidth margin="normal">
                        <FormControlLabel label="Visible" control={<Switch
                            checked={theme.visible}
                            disabled={loading}
                            name="visible"
                            onChange={handleChanges}
                        />}/>
                        <FormControlLabel label="Premium" control={<Switch
                            checked={theme.premium}
                            disabled={loading}
                            name="premium"
                            onChange={handleChanges}
                        />}/>
                        <FormControlLabel label="New" control={<Switch
                            checked={theme.is_new}
                            disabled={loading}
                            name="is_new"
                            onChange={handleChanges}
                        />}/>
                        <Grid>
                            <FormControlLabel label="Paint icon" control={<BlackAndWhiteSwitch
                                checked={theme.paint_icon}
                                disabled={loading}
                                name="paint_icon"
                                onChange={handleChanges}
                            />}/>
                            <Chip label={theme.paint_icon ? 'black' : 'white'} variant="outlined"/>
                        </Grid>
                    </FormControl>
                </Grid>
                <Grid item xs={6}>
                    <Paper>
                        <Box p={3}>
                            <div style={{marginBottom: 20, textAlign: 'center', position: 'relative'}}>
                                {preview && theme && theme.image && !loading && <div style={{
                                    width: '100%',
                                    position: 'absolute',
                                    top: 235,
                                    color: theme.font_color,
                                    fontSize: 23,
                                    fontFamily: preview.styles.font.family,
                                    fontWeight: preview.styles.font.weight,
                                    fontStyle: preview.styles.font.italic ? 'italic' : 'normal',
                                }}>
                                    {preview.text}
                                </div>}

                                {theme.image && <img alt="theme" src={theme.image} style={{height: 500}}
                                />}
                            </div>
                            <input
                                hidden
                                accept="image/*"
                                id="icon-button-photo"
                                onChange={handleImageUpload}
                                type="file"
                            />
                            <label htmlFor="icon-button-photo">
                                <Button disabled={loading} component="span" variant="contained" startIcon={<CloudUploadIcon/>}>
                                    Upload image
                                </Button>
                            </label>

                            {preview && theme && theme.image && !loading && <FormControl fullWidth margin="normal">
                                <TextField
                                    disabled={loading}
                                    label="Preview text"
                                    onChange={handlePreviewChanges}
                                    value={preview.text}
                                />
                            </FormControl>}
                        </Box>
                    </Paper>
                </Grid>
            </Grid>

            <Box mt={2}>
                <Box display="flex">
                    <Box flexGrow={1}>
                        {theme.id && <Button variant="contained" disabled={loading} color="primary" onClick={updateTheme}>Update</Button>}
                        {!theme.id && <Button variant="contained" disabled={loading} color="primary" onClick={createTheme}>Create</Button>}
                        <Button style={{marginLeft: 10}} component={RouterLink} to="/themes">Cancel</Button>
                    </Box>
                    <Box>
                        {theme.id && <Button variant="contained" onClick={() => handleOpenDeleteDialog(props.id)} color="secondary">Delete </Button>}
                    </Box>
                </Box>
            </Box>
        </Box>

        <Dialog open={deleteBox.open} onClose={() => handleCloseDeleteDialog()}>
            <DialogTitle>Delete theme</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {deleteBox.id && `Theme #${deleteBox.id} will be deleted`}
                    {!deleteBox.id && `${deleteBox.message}`}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => handleCloseDeleteDialog()} autoFocus color="primary">Cancel</Button>
                <Button onClick={() => handleDelete()} variant="contained" color="secondary">Delete</Button>
            </DialogActions>
        </Dialog>
    </Paper>
}
